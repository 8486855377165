import Cookies from 'universal-cookie';
import { CookieSetOptions } from 'universal-cookie/lib/types';

export interface CookieUtilsAppVars {
   useSecureCookies?: boolean;
   sameSiteValue?: 'Lax' | 'None' | 'Strict';
}

declare const App: CookieUtilsAppVars;

export enum SameSite {
   none = 'none',
   lax = 'lax',
   strict = 'strict',
}

export interface CookieUtilsOptions {
   path?: string;
   expires?: Date;
   maxAgeInSeconds?: number;
   domain?: string;
   secure?: boolean;
   httpOnly?: boolean;
   sameSite?: SameSite;
   encode?: (value: string) => string;
}

export class CookieUtils {
   static set(name: string, value: any, options: CookieUtilsOptions = {}): string {
      const convertedOptions = CookieUtils.convertCustomOptions({
         ...CookieUtils.getDefaultOptions(),
         ...options,
      });
      const cookies = new Cookies();
      cookies.set(name, value, convertedOptions);

      return name;
   }

   private static getDefaultOptions(): CookieUtilsOptions {
      const options: CookieUtilsOptions = {
         sameSite: SameSite.lax,
         secure: true,
         // The cookie should be accessible on all pages by default. Without setting '/' as the path
         // the cookie library will default to the current page when set is called.
         path: '/',
      };

      if (typeof App.useSecureCookies === 'boolean') {
         options.secure = App.useSecureCookies;
      }

      if (App.sameSiteValue === 'None') {
         options.sameSite = SameSite.none;
      } else if (App.sameSiteValue === 'Strict') {
         options.sameSite = SameSite.strict;
      }

      return options;
   }

   private static convertCustomOptions(options: CookieUtilsOptions): CookieSetOptions {
      const convertedOptions = { ...options };
      if (convertedOptions.maxAgeInSeconds !== undefined) {
         (convertedOptions as CookieSetOptions).maxAge = convertedOptions.maxAgeInSeconds;
         delete convertedOptions.maxAgeInSeconds;
      }
      return convertedOptions;
   }
}
